<template>
  <b-container>
    <h1>{{ $t("users.Users") }}</h1>
    <section
      class="d-flex justify-content-between align-items-center mb-3 flex-column flex-md-row"
    >
      <div class="d-flex justify-content-start align-items-center flex-fill">
        <i class="fas fa-search mr-2"></i>
        <b-form-input
          :placeholder="$t('users.search')"
          v-model="searchString"
          autofocus
          class="flex-fill"
          autocomplete="off"
        ></b-form-input>
      </div>

      <b-button class="ml-md-2" @click="createUser"
        ><i class="fas fa-user-plus mr-2"></i
        >{{ $t("users.createUser") }}</b-button
      >
    </section>

    <b-table striped hover :items="showData" :fields="fields" stacked="md" sticky-header :busy="loading">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(username)="data">
        <router-link :to="`/users/${data.value}`" class="user-link">
          {{ data.value }}
        </router-link>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import axios from "axios";
// import moment from "moment";
import { /*mapGetters,*/ mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {},
  name: "Users",
  data() {
    return {
      loading: false,
      data: null,
      showData: null,
      searchString: "",
      fields: [
        {
          key: "username",
          label: this.$t("users.username"),
          sortable: true,
        },
        {
          key: "firstName",
          label: this.$t("users.firstName"),
          sortable: true,
        },
        {
          key: "lastName",
          label: this.$t("users.lastName"),
          sortable: true,
        },
        {
          key: "lastLogin",
          label: this.$t("users.lastLogin"),
          formatter: (value /*, key, index*/) => {
            // return moment(value).format("DD.MM.YYYY HH:mm");
            return value == "-"
              ? this.$t("user.never")
              : this.$d(new Date(value), "datetime-short");
          },
          sortable: true,
        },
        {
          key: "roles",
          label: this.$t("users.roles"),
        },
      ],
    };
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    generateUserTableData(responseData) {
      let viewData = [];
      responseData.forEach((el) => {
        let row = {};
        row["username"] = el["name"];
        row["roles"] = el["roles"].join(", ");
        // row['last_login'] = el['attributes'].filter((att) => {return att['name'] === 'lastLogin'})[0]['value'];
        row = {
          ...row,
          ...this.getAttributes(el["attributes"], [
            "lastLogin",
            "firstName",
            "lastName",
          ]),
        };

        viewData.push(row);
      });
      return viewData;
    },
    getAttributes(attributes, names) {
      let returnAttributes = {};
      attributes.forEach((att) => {
        if (names.includes(att.name)) {
          returnAttributes[att.name] = att.value;
        }
      });
      names.forEach((name) => {
        if (!(name in returnAttributes)) {
          returnAttributes[name] = "-";
        }
      });
      return returnAttributes;
    },
    filterData(data, search) {
      return _.filter(
        data,
        _.flow(
          _.values,
          _.partialRight(_.some, _.method("match", new RegExp(search, "i")))
        )
      );
    },
    createUser() {
      this.$router.push({ name: "CreateUser" });
    },
  },
  mounted() {
    this.loading = true;
    axios
      .get(
        "my-devices/api/users?includeFullInfo=true"
      )
      .then((res) => {
        this.data = this.generateUserTableData(res.data);
      })
      .catch((err) => {
        this.data = null;
        this.fireAlert([err, "danger"]);
      })
      .finally(() => {
        this.showData = this.data;
        //wait a little time to force the table be in loading state. Otherwise the page will scroll automatcally down after setting the data.
        setTimeout(()=>{this.loading = false;}, 500)
      });
  },
  watch: {
    searchString: function (newValue) {
      if (newValue == "") {
        this.showData = this.data;
        return;
      }
      this.showData = this.filterData(this.data, newValue);

      // _.debounce(function(){this.showData = this.filterData(this.data, newValue)}, 500);
    },
  },
};
</script>

<style lang="scss">
@import "@/theme.scss";
.user-link {
  color: $black;
  text-decoration: underline;
}
</style>